import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Hero from '../components/UI/Hero'
import Container from '../components/UI/Container'
import TeamMember from '../components/UI/TeamMember'
import SEO from '../components/SEO'
import styled from 'styled-components'
import _ from 'underscore'

const TeamWrapper = styled.div`
  margin-top: 2rem;
  section {
    flex-wrap: wrap;
  }
`

class Team extends React.Component {
  _renderTeamCards = () => {
    let { edges } = this.props.data.allAirtable
    return _.map(edges, (item, index) => {
      let { image, title, name, bio } = item.node.data
      return (
        <TeamMember
          key={index}
          image={image.localFiles[0].childImageSharp.fluid}
          name={name}
          title={title}
          bio={bio}
        />
      )
    })
  }

  render() {
    return (
      <Fragment>
        <SEO title={'Team'} />
        <Hero heroStyle="tertiary" title="Meet The Team" />
        <TeamWrapper>
          <Container>{this._renderTeamCards()}</Container>
        </TeamWrapper>
      </Fragment>
    )
  }
}

export default Team

export const teamQuery = graphql`
  query {
    allAirtable(filter: { table: { eq: "Team" } }) {
      edges {
        node {
          data {
            name
            title
            image {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            bio
          }
        }
      }
    }
  }
`
