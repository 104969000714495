import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'

const TeamImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 300px;

  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
`

const Wrapper = styled.section`
  width: 48%;
  margin-right: 2%;

  @media (max-width: ${props => props.theme.sizes.mobile}) {
    width: 100%;
    margin-right: 0 !important;
  }

  &:nth-child(2n) {
    margin-right: 0;
  }
  .card {
    p {
      margin: 0;
    }
    .top {
      background: ${props => props.theme.colors.grey};
      position: relative;
      height: 300px;
    }
    .bottom {
      background: #ffffff;
      margin-bottom: 2rem;
      box-shadow: 0 5px 0 0 ${props => props.theme.colors.red},
        1px 2px 11px 0 rgba(0, 0, 0, 0.5);
      .bio {
        p {
          margin-bottom: 1rem;
          &:last-child {
            margin: 0;
          }
        }
      }
      div {
        padding: 0.5rem 1rem;
        &:first-child {
          p {
            font-weight: bold;
            font-size: 18px;
          }
        }
        &:nth-child(2n) {
          margin-right: 0;
          background: ${props => props.theme.colors.greyLight};
        }
      }
    }
  }
`

const TeamMember = props => (
  <Wrapper>
    <div className="card">
      <div className="top">
        <TeamImg fluid={props.image} backgroundColor="#232324" />
      </div>
      <div className="bottom">
        <div className="name">
          <p>{props.name}</p>
        </div>
        <div className="title">
          <p>{props.title}</p>
        </div>

        <div
          className="bio"
          dangerouslySetInnerHTML={{
            __html: props.bio,
          }}
        />
      </div>
    </div>
  </Wrapper>
)

TeamMember.propTypes = {
  image: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
}

export default TeamMember
